<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Producto Anulados</strong>
          </CCardHeader>
          <CCardBody>
            <b-row class="justify-content-md-center">

                <b-col md="2">
                  <b-form-group label="Mozo :">
                    <b-form-select v-model="report.id_moso" :options="mosos"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Turno :">
                    <b-form-select v-model="report.turn" :options="turn"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Categoria :">
                    <b-form-select @change="ListLetter" v-model="report.id_category_letter" :options="category_letter"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Platos :">
                    <v-select placeholder="Todos" label="text" class="w-100"  v-model="letter" :options="letters"></v-select>
                  </b-form-group>
                </b-col>

                

                <b-col md="2">
                  <b-form-group label="Desde:">
                    <b-form-input class="text-center" type="date" v-model="report.from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Hasta:">
                    <b-form-input class="text-center" type="date" v-model="report.to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Seleccione un fecha</small>
                  </b-form-group>
                </b-col>


                <b-col md="1">
                  <b-form-group label=".">
                    <b-button @click="Report" title="Buscar" type="button" variant="primary" class="form-control"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>
            </b-row>
            

            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Fecha</th>
                    <th width="8%" class="text-center">Mesa</th>
                    <th width="37%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Categoria</th>
                    <th width="10%" class="text-center">Mozo</th>
                    <th width="25%" class="text-center">Motivo</th>
                    <th width="7%" class="text-center">Cantidad</th>
                    <th width="3%" class="text-center">Acc.</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.table_name }}</td>
                    <td class="text-left"> {{ item.name}}</td>
                    <td class="text-left"> {{ item.category_letter_name }}</td>
                    <td class="text-left"> {{ item.moso_name }}</td>
                    <td class="text-left"> {{ item.reason }}</td>
                    <td class="text-right"> {{ item.quantity }}</td>
                    <td class="text-right"><b-form-checkbox  size="sm" v-model="item.state" value="0" unchecked-value="1" ></b-form-checkbox>    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row>
                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="button" @click="SendTrash" class="form-control" variant="primary" ><i class="fas fa-trash-alt"></i> Enviar a Papelera</b-button>
                </b-col>
            </b-row>
             
 
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

      <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'
export default {
  name: "RquirementList",
  components:{
    LoadingComponent,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      module: 'LetterCanceled',
      role:1,
      data_table: [],
      report:{
        id_moso:'all',
        id_category_letter:'all',
        turn:'all',
        from:moment(new Date()).local().format("YYYY-MM-DD"),
        to:moment(new Date()).local().format("YYYY-MM-DD"),
        type:'arsort',
      },
      mosos:[],
      category_letter:[],
      letter : null,
      letters : [],
      type:[
        {value:'arsort',text:'Mayor a Menor'},
        {value:'asort',text:'Menor a Mayor'},
      ],
      turn:[
        {value:'all',text:'Todos'},
        {value:'1',text:'Desayuno'},
        {value:'2',text:'Almuerzo'},
        {value:'3',text:'Cena'},
      ],
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListMosos();
    this.ListCategoryLetter();
    this.ListLetter();
  },
  methods: {
    ListCategoryLetter,
    ListLetter,
    ListMosos,
    Report,
    ExportExcel,
    SendTrash,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function SendTrash() {
  let me = this;
   let url = me.url_base + "order/trash-order-detail";
  let data = {
    order_detail:me.data_table,
    id_user:me.user.id_user, 
  }
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token,  module: me.module, role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.Report();
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function ListCategoryLetter() {

  let me = this;
  let url = this.url_base + "category-letter/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.category_letter = [{value:'all',text:'-- Todos --'}];
      if (response.data.status == 200) {
        me.report.id_letter = 'all';
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.category_letter.push({value: element.id_category_letter, text: element.name});
        }
      }
    })
}


function ListLetter() {

  let me = this;
  let url = this.url_base + "letter/list-by-category/"+this.report.id_category_letter;
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.letters = [{value:'all',text:'-- Todos --'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.letters.push({value: element.id_letter, text: element.name});
        }
      }
    })
}



function ListMosos() {

  let me = this;
  let url = this.url_base + "moso/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: me.token, module: me.module, role: me.role,},
  })
    .then(function (response) {
      me.mosos = [{value:'all',text:'-- Todos --'}];
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.mosos.push({value: element.id_moso, text: element.description});
        }
      }
    })
}

function Report() {
  this.errors.from = false;
  this.errors.to = false;
  if (this.report.from.length == 0) {
    this.errors.from = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  if (this.report.to.length == 0) {
    this.errors.to = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  this.isLoading = true;
  let me = this;
  let url = this.url_base + "report/dish-canceled";
  me.report.id_letter = this.letter == null ? 'all':this.letter.value;
  let data = me.report;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module,role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.result;
        me.isLoading = false;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
        me.isLoading = false;
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function ExportExcel() {

  this.errors.from = false;
  this.errors.to = false;
  if (this.report.from.length == 0) {
    this.errors.from = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  if (this.report.to.length == 0) {
    this.errors.to = true;
    Swal.fire({ icon: 'warning', text: 'Complete los cambos obligatorios', timer: 3000,})
    return false;
  }

  let me = this;
  me.report.id_letter = this.letter == null ? 'all':this.letter.value;
  let url = this.url_base + "excel-report-dish-canceled/"+ this.report.id_moso+"/"+ this.report.turn+"/"+ this.report.id_category_letter+"/"+ this.report.id_letter+"/"+ this.report.from +"/"+ this.report.to;
  window.open(url,'_blank');
}

</script>
